.ListChoiceMessageChatGpt {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  height: 30px;
  background-color: #ffffff;
  padding-left: 10px;
  padding-bottom: 15px;
  padding-top: 10px;

  .wrap-group-next {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;
  }
}
